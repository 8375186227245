function ajaxInit($) {

   /* ====================== REMOVE EMPTY P TAG ======================  */
      $('p').each(function() {
          var $this = $(this);
          if ($this.html().replace(/\s|&nbsp;/g, '').length == 0)
              $this.remove();
      });

  /* ====================== INIT FUNCSTIONS ======================  */
    wow = new WOW(
    {
    boxClass:     'wow',      // default
    animateClass: 'animated', // default
    offset:       0,          // default
    mobile:       true,      
    live:         true        // default
    }
                    )
    wow.init();

  /* ====================== WINDOW REISZE FUNCTION  ======================  */
    $(window).resize(function() {
    })
    /*Navtoggle*/
    $('.navtoggle').on('click', function() {
      $('body').toggleClass('active');
      $('html').toggleClass('active');
    })

    $(document).on('click', function(e) {
      e.stopPropagation();
      var nav = $('.navigation');
      var navtoggle = $('.navtoggle');
      if( (!navtoggle.is(e.target) && navtoggle.has(e.target).length == 0 && !nav.is(e.target) && nav.has(e.target).length == 0)) {
        $('html').removeClass('active');  
        $('body').removeClass('active');  
      }
    })

    /*Nav scroll effect*/
    $(window).on('scroll', function() {
        var windscroll = $(window).scrollTop();
        if (windscroll >= 100) {
          $('.header').addClass('onScroll');
        } else {
          $('.header').removeClass('onScroll');
        }

    })
    
    /*Awards Slider*/
    $('.awards-slider').owlCarousel({
      loop:true,
      margin:30,
      nav:false,
      autoplay: true,
      smartSpeed: 1800,
      responsive:{
          0:{
            items:2
          },
          768: {
            items: 3
          },
          1024:{
            items:7,
          }
      }
    });

    /*About Us Awards Slider*/
    $('.awards-about-slider').owlCarousel({
      loop:true,
      margin:30,
      nav:false,
      autoplay: true,
      smartSpeed: 1800,
      responsive:{
          0:{
            items:2
          },
          768: {
            items: 3
          },
          1024:{
            items:7,
          }
      }
    });

    /*Partner Slider*/
    $('.partners-slider').owlCarousel({
      loop:true,
      margin:30,
      nav:false,
      dots: false,
      autoplay: true,
      smartSpeed: 1800,
      responsive:{
          0:{
            items:1
          },
          768: {
            items: 3
          },
          1024:{
            items:6,
          }
      }
    });

    /* Testimonial slider Init */
    $('.testimonials__slider').owlCarousel({
        loop:true,
        autoplay: true,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        smartSpeed: 1500,
        margin:30,
        items: 1,
        dots: false,
        nav:false,
        navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"]
    });

    /*FAQs accordion*/

    $('.faq').on('click', function() {
      // alert('clicked');
      $(this).toggleClass('open');
    })

    /*Single Project delay*/
    setTimeout(function() {
      if( $('body').hasClass('single-project') ) {
        $('html, body').addClass('loaded');
      }
    }, 1000);

    /*Project Transition*/
    if( $(window).width() >= 768 ) {
      var project = $('.project');

      project.on('click', function(e) {
        e.preventDefault();
        var image = $(this).css('background-image');
        image = image.replace('url(','').replace(')','').replace(/\"/gi, "");
        var link = $(this).attr('href');
        var title = $(this).find('.title').text();
        var desc = $(this).find('.description').text();

        var transition = $('.project-transition');

        var menu = $('.header');

        menu.addClass('hide');
        transition.css('background-image', "url("+image+")");

        var scrollVal = ( $(this).offset().top ) - ( ( ( $(window).height() - $(this).outerHeight() ) / 2 ) );

        setTimeout(function() {
          transition.parents('.transition').addClass('active');
        }, 200);

        setTimeout(function() {
          window.location = link;
        }, 400)

        
      })
    }
}
jQuery(document).ready(function($) {

  ajaxInit(jQuery);

  // const getUrl = window.location;
  // const getHomeUrl = getUrl.protocol + "//" + getUrl.host;

  // //
  // // Barba.js
  // //

  // // Barba.Pjax.Dom.wrapperId = 'ajax-wrapper';
  // // Barba.Pjax.Dom.containerClass = 'ajax-content';
  // // Barba.Pjax.ignoreClassLink = 'no-ajax';

  // var FadeTransition = Barba.BaseTransition.extend({
  //     start: function() {
  //         /**
  //          * This function is automatically called as soon the Transition starts
  //          * this.newContainerLoading is a Promise for the loading of the new container
  //          * (Barba.js also comes with an handy Promise polyfill!)
  //          */

  //         // As soon the loading is finished and the old page is faded out, let's fade the new page
  //         Promise
  //             .all([this.newContainerLoading, this.fadeOut()])
  //             .then(this.fadeIn.bind(this));

  //     },

  //     fadeOut: function() {
  //         /**
  //          * this.oldContainer is the HTMLElement of the old Container
  //          */

  //         //return $(this.oldContainer).animate({ opacity: 0 }).promise();
  //         return $(this.oldContainer).animate({ opacity: 0 }).promise();
    

  //     },

  //     fadeIn: function() {
  //         /**
  //          * this.newContainer is the HTMLElement of the new Container
  //          * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
  //          * Please note, newContainer is available just after newContainerLoading is resolved!
  //          */

  //         var _this = this;
  //         var $newContainer = $(this.newContainer);

  //         $(this.oldContainer).hide();
  //         $(window).scrollTop(0); // scroll to top here

  //         $newContainer.css({
  //             visibility : 'visible',
  //             opacity : 0,
  //             "margin-left" : "30px",
  //         });

  //         $newContainer.animate({
  //             opacity: 1,
  //             "margin-left" : 0,
  //         }, 400, function() {
  //             /**
  //              * Do not forget to call .done() as soon your transition is finished!
  //              * .done() will automatically remove from the DOM the old Container
  //              */

  //             _this.done();
  //         });
  //     }
  // });

  // /**
  //  * Next step, you have to tell Barba to use the new Transition
  //  */

  // Barba.Pjax.getTransition = function() {
  //     /**
  //      * Here you can use your own logic!
  //      * For example you can use different Transition based on the current page or link...
  //      */

  //     return FadeTransition;
  // };

  // var CommonView = Barba.BaseView.extend({
  //      namespace: 'common',
  //      onEnterCompleted: function () {
  //        // The Transition has just finished.
  //      },
  //    });
  //    CommonView.init();

  //    Barba.Dispatcher.on('transitionCompleted', function(currentStatus) {
  //      console.log('loaded')
  //      wow.sync();
  //    })

  // Barba.Pjax.start()
  // Barba.Prefetch.init();

  // Barba.Dispatcher.on('newPageReady', function(currentStatus, oldStatus, container, newPageRawHTML) {

  //     var response = newPageRawHTML.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', newPageRawHTML)
  //     var bodyClasses = $(response).filter('notbody').attr('class');

  //     if( bodyClasses.indexOf('single-project') > -1) {
  //       var newClasses = bodyClasses + ' loaded';
  //       $('body').attr('class', newClasses);
  //     } else {
  //       $('body').attr('class', bodyClasses);
  //     }
      
  // });
});

jQuery( function($) { // Ready
  function addBlacklistClass() {
      $( 'a' ).each( function() {
          if ( this.href.indexOf('/wp-admin/') !== -1 || 
               this.href.indexOf('/wp-login.php') !== -1 ) {
              $( this ).addClass( 'wp-link' );
          }
      });
  }

  function initContactForm() {
   $( 'div.wpcf7 > form' ).each( function() {
    var $form = $( this );
    wpcf7.initForm( $form );
    if ( wpcf7.cached ) {
     wpcf7.refill( $form );
    }
   });
  } 
    addBlacklistClass();
       var settings = { 
           anchors: '#menu-main-menu a',
           blacklist: '.wp-link',
           onStart: {
               duration: 280, // ms
               render: function ( $container ) {
                   $container.addClass( 'slide-out' );
               }
           },
           onAfter: function($container) {
               addBlacklistClass();

               var $hash = $( window.location.hash );
                
               if ( $hash.length !== 0 ) {

                   var offsetTop = $hash.offset().top;

                   $( 'body, html' ).animate( {
                           scrollTop: ( offsetTop - 60 ),
                       }, {
                           duration: 280
                   } );
               }

               $container.removeClass( 'slide-out' );
               ajaxInit(jQuery);
               initContactForm();
           }
       };

    $( '#main' ).smoothState( settings );
} );
